import './App.scss';
import {Route, Routes} from 'react-router-dom';
import Nav from "./Components/Commons/Nav";
import {APP_PAGES, APP_URLS} from "./urls";
import {lazy, Suspense, useEffect, useState} from "react";
import {useExpand, useInitData} from "@vkruglikov/react-telegram-web-app";
import i18n from "i18next";
import {useTranslation, initReactI18next} from "react-i18next";
import en from './locales/en.json'
import ru from './locales/ru.json'
import Loader from "./Components/Commons/Loader";
import WebFont from "webfontloader";
import {Toaster} from 'react-hot-toast';
import RotatedDevice from "./Components/Commons/RotatedDevice";
import DetectDevice from "./Components/Commons/DetectDevice";
import Bowser from 'bowser';
import Airdrop from "./Components/Airdrop";
import Clash from "./Components/PTE/Clash";
import PvpFight from "./Components/PVP";
import pkg from '../package.json';

const MainPage = lazy(() => import ("./Components/MainPage"))
const Boosts = lazy(() => import ("./Components/Boosts"))
const Tasks = lazy(() => import ("./Components/Tasks"))
const PTE = lazy(() => import ("./Components/PTE"))
const Friends = lazy(() => import ("./Components/Friends"))
const PenaltyShot = lazy(() => import ("./Components/PTE/PenaltyShot"))
const FlipCard = lazy(() => import ("./Components/PTE/FlipCard"))
const Combo = lazy(() => import ("./Components/PTE/Combo"))

i18n.use(initReactI18next).init({
    resources: {
        en: en,
        ru: ru
    },
    lng: "en",
    fallbackLng: "en"
});

const Root = (props) => {

    const {t} = useTranslation();

    const {tg} = props
    const [isExpanded, expand] = useExpand();
    const [isMobile, setIsMobile] = useState(true)
    const [initDataUnsafe, initData] = useInitData();

    if (tg) {
        tg.ready()
        tg.isClosingConfirmationEnabled = true
    }

    useEffect(() => {
        tg?.setHeaderColor('#000')
        tg?.setBackgroundColor('#000')
        expand()

        const parser = Bowser.getParser(navigator.userAgent);

        if (initData && (parser.parsedResult.os.name.toLowerCase() === "android" || parser.parsedResult.os.name.toLowerCase() === "ios")) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
            // setIsMobile(true)
        }
    }, [])

    useEffect(() => {
        WebFont.load({
            custom: {
                families: ['Saira-300', 'Saira-400', 'Saira-500', 'Saira-600', 'Saira-700'],
            },
        });
    }, []);

    return (
        <>
            {!isMobile ? <DetectDevice t={t}/> :
                <>
                    <RotatedDevice t={t}/>
                    <div className="app">
                        <div className="app_wrapper">
                            <div className="pages">
                                <Routes>
                                    <Route index element={
                                        <Suspense fallback={<Loader/>}>
                                            <Airdrop {...props}/>
                                        </Suspense>
                                    }/>
                                </Routes>
                            </div>
                        </div>
                        <Toaster/>
                    </div>
                </>
            }
        </>
    );
}

export default Root;
