import * as airdropActions from './constants.js'
import {handleRespErr} from '../../../helpers/respErrorsHandler'
import {GetAxios} from '../../../helpers/axios';
import {API_URLS, buildAPIUrl} from "../../../urls";
import {toastError, toastSuccess} from "../../../helpers/toaster";

//Get Airdrop Info

export function getResultAction(user_id) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(getResultActionRequest());

        axios.post(buildAPIUrl(API_URLS.airdrop.result + `/${user_id}`), {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(getResultActionSuccess(res.data));
            })
            .catch(err => {
                dispatch(getResultActionFailure())
            })
    }
}


function getResultActionRequest() {
    return {
        type: airdropActions.GET_RESULT_REQUEST,
    }
}

function getResultActionSuccess(airdropInfo) {
    return {
        type: airdropActions.GET_RESULT_SUCCESS,
        payload: airdropInfo
    }
}

function getResultActionFailure(error) {
    return {
        type: airdropActions.GET_RESULT_FAILURE,
        error: error
    }
}


//Get Airdrop Info

export function getAirdropAction(user_id) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(getAirdropActionRequest());

        axios.post(buildAPIUrl(API_URLS.airdrop.info + `/${user_id}`), {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(getAirdropActionSuccess(res.data));
            })
            .catch(err => {
                dispatch(getAirdropActionFailure())
            })
    }
}


function getAirdropActionRequest() {
    return {
        type: airdropActions.GET_AIRDROP_INFO_REQUEST,
    }
}

function getAirdropActionSuccess(airdropInfo) {
    return {
        type: airdropActions.GET_AIRDROP_INFO_SUCCESS,
        payload: airdropInfo
    }
}

function getAirdropActionFailure(error) {
    return {
        type: airdropActions.GET_AIRDROP_INFO_FAILURE,
        error: error
    }
}

//Link Account

export function linkAccountAction(user_id, data, error_cb, success_cb) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(linkAccountActionRequest());

        axios.post(buildAPIUrl(API_URLS.airdrop.link_account + `/${user_id}`), {...data}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (res.data.is_client) {
                    dispatch(linkAccountActionSuccess({is_client: true}))
                    success_cb()
                } else {
                    dispatch(linkAccountActionSuccess())
                    error_cb()
                }
            })
            .catch(err => {
                dispatch(linkAccountActionFailure())
            })
    }
}


function linkAccountActionRequest() {
    return {
        type: airdropActions.LINK_ACCOUNT_REQUEST,
    }
}

function linkAccountActionSuccess(linkAccountData) {
    return {
        type: airdropActions.LINK_ACCOUNT_SUCCESS,
        payload: linkAccountData
    }
}

function linkAccountActionFailure(error) {
    return {
        type: airdropActions.LINK_ACCOUNT_FAILURE,
        error: error
    }
}

//Link Account

export function verifyAction(user_id, data, error_cb, success_cb) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(verifyActionRequest());

        axios.post(buildAPIUrl(API_URLS.airdrop.verify_otp + `/${user_id}`), {...data}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                toastSuccess('Account linked successfully')
                dispatch(verifyActionSuccess())
                dispatch(getAirdropAction(user_id))
            })
            .catch(handleRespErr(dispatch, toastError, verifyActionFailure))
    }
}


function verifyActionRequest() {
    return {
        type: airdropActions.VERIFY_OTP_REQUEST,
    }
}

function verifyActionSuccess(verifyData) {
    return {
        type: airdropActions.VERIFY_OTP_SUCCESS,
        payload: verifyData
    }
}

function verifyActionFailure(error) {
    return {
        type: airdropActions.VERIFY_OTP_FAILURE,
        error: error
    }
}

//Create Account

export function createAccountAction(user_id, data) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(createAccountActionRequest());

        axios.post(buildAPIUrl(API_URLS.airdrop.create_account + `/${user_id}`), {...data}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                toastSuccess('Account created successfully')
                dispatch(createAccountActionSuccess())
                dispatch(getAirdropAction(user_id))
            })
            .catch(handleRespErr(dispatch, toastError, createAccountActionFailure))
    }
}

function createAccountActionRequest() {
    return {
        type: airdropActions.CREATE_ACCOUNT_REQUEST,
    }
}

function createAccountActionSuccess(account) {
    return {
        type: airdropActions.CREATE_ACCOUNT_SUCCESS,
        payload: account
    }
}

function createAccountActionFailure(error) {
    return {
        type: airdropActions.CREATE_ACCOUNT_FAILURE,
        error: error
    }
}

export function setAirdropDefault() {
    return {
        type: airdropActions.SET_AIRDROP_DEFAULT
    }
}