import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as airdropActions from '../store/actions/airdropActions';
import {createAccountAction, linkAccountAction, setAirdropDefault} from "../store/actions/airdropActions";


const withAirdrop = WrappedComponent => {
    class withAirdrop extends Component {
        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        result: state.airdrop.result,
        airdropInfo: state.airdrop.airdropInfo,
        linkAccountData: state.airdrop.linkAccountData,
        isAirdropLoading: state.airdrop.isAirdropLoading,
        isProceedAirdrop: state.airdrop.isProceedAirdrop,
        hasError: state.airdrop.hasError
    });

    const mapDispatchToProps = (dispatch) => ({
        getResult: (user_id) => dispatch(airdropActions.getResultAction(user_id)),
        getAirdrop: (user_id) => dispatch(airdropActions.getAirdropAction(user_id)),
        createAccountAction: (user_id, data) => dispatch(airdropActions.createAccountAction(user_id, data)),
        linkAccountAction: (user_id, data, error_cb, success_cb) => dispatch(airdropActions.linkAccountAction(user_id, data, error_cb, success_cb)),
        verifyOtpAction: (user_id, data, error_cb, success_cb) => dispatch(airdropActions.verifyAction(user_id, data, error_cb, success_cb)),
        setAirdropDefault: () => dispatch(airdropActions.setAirdropDefault()),
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(withAirdrop);
};

export default withAirdrop;
