export const APP_URLS = {
    routes: {
        main: '/main',
        tasks: '/tasks',
        boosts: '/boosts',
        friends: '/friends',
        airdrop: '/airdrop',
        p_t_e: '/play-to-earn',
        p_v_p: '/pvp-fight',
    }
}

export const APP_PAGES = {
    penalty_game: APP_URLS.routes.p_t_e + '/penalty-game',
    flip_game: APP_URLS.routes.p_t_e + '/flip-game',
    combo_game: APP_URLS.routes.p_t_e + '/combo-game',
    clash: APP_URLS.routes.p_t_e + '/clash-game'
}

export const API_URLS = {
    dashboard: '/get-info',
    boosts_list: '/boosts/list',
    apply_boost: '/boosts/claim',
    claim: '/claim',
    pte: {
        list: '/mini-games/list',
        game_data: '/mini-games',
        play: '/mini-games/play',
        penalty: {
            data: '/penalty/data',
            play: '/penalty/play',
        }
    },
    friends: {
        list: '/referrals'
    },
    airdrop: {
        result: '/statistics',
        info: '/airdrop',
        link_account: '/linking-account',
        verify_otp: '/check-code',
        create_account: '/sign-up-account'
    },
    tasks: {
        daily_list: '/tasks/daily',
        daily_check: '/tasks/daily/claim',
        community_list: '/tasks/list',
        community_claim: '/tasks/claim',
    }
}

export const buildAPIUrl = url => {
    if (process.env.NODE_ENV === "development") {
        return url
    } else {
        return process.env.REACT_APP_NODE_HTTP_HOST + url
    }
};
export const buildWSUrl = url => {
    if (process.env.NODE_ENV === "development") {
        // return process.env.REACT_APP_NODE_WS_HOST + url
        return 'wss://tapp-stg.mpeppe.io/v1' + url
    } else {
        // return process.env.REACT_APP_NODE_WSS_HOST + url
        return 'wss://tapp-stg.mpeppe.io/v1' + url
    }
};