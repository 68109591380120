import React, {useEffect, useState} from 'react';
import WithLoading from "../HOC/withLoading";
import WithFrame from "../HOC/WithFrame";
import Loader from "../Commons/Loader";
import {isImagesLoaded} from "../../helpers/imagesLoaded";
import classNames from "classnames";
import {useInitData} from "@vkruglikov/react-telegram-web-app";
import {useNavigate} from "react-router-dom";
import withAirdrop from "../../Containers/withAirdrop";
import {makeConfetti} from "../../helpers/confetti";
import {numberWithCommasNull} from "../../helpers/numberWithCommas";

const Airdrop = (props) => {

    const navigate = useNavigate();
    const {
        tg,
        isAirdropLoading,
        setAirdropDefault,
        hasError,
        getResult,
        result
    } = props

    const [imagesLoaded, setImagesLoaded] = useState(false)
    const [initDataUnsafe, initData] = useInitData();

    useEffect(() => {
        tg?.BackButton?.show()
        tg?.BackButton?.onClick(() => navigate('/'))
        isImagesLoaded(['/images/friends.png'], null, setImagesLoaded)
        initDataUnsafe?.user && getResult(initDataUnsafe?.user.id)
        // getResult(6699466438)

        return () => {
            setAirdropDefault()
        }
    }, []);

    useEffect(() => {
        if(result && result.is_counted && result.is_linked) {
                setTimeout(() => {
                    makeConfetti(200)
                    tg?.HapticFeedback.notificationOccurred('success')
                }, 1000)
        }
    }, [result]);

    if (!imagesLoaded || (!result && !hasError)) {
        return <Loader/>
    }

    return (
        <WithLoading
            isLoading={isAirdropLoading}
        >
            <WithFrame
                className={'friends_page'}
                header={
                    <div>Mpeppe Airdrop</div>
                }
            >
                <img width={226} height={227} className={'friends_page_banner'}
                     src={`/images/drop.png?v=1`}
                     alt={'friends icon'}/>
                {hasError ? <div className={'has_error'}>
                        <p>Something went wrong...</p>
                        <svg className={classNames({
                            "has_error_svg": true,
                            "rotate": isAirdropLoading
                        })} onClick={() => getResult(initDataUnsafe?.user.id)} version="1.1"
                             xmlns="http://www.w3.org/2000/svg"
                             x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256">
                            <g>
                                <g>
                                    <g>
                                        <path fill="#fff"
                                              d="M116.3,38c-15.7,2.4-30.5,8.6-43,18.2C69.2,59.3,61.4,67,57.9,71.4c-11.8,14.8-18.8,32.8-19.7,50.9l-0.3,5.5l-14,0.1L10,128l28.2,30.7c15.5,16.9,28.4,30.8,28.6,30.8c0.3,0,12.5-13.8,27.2-30.7l26.7-30.8l-14.7-0.1l-14.8-0.1l0.2-1.1c2.2-10.2,4.6-17.3,8.6-25.3c12.3-24.2,34.6-41.7,60.9-47.7c3.8-0.9,12.8-2.2,15.4-2.2h1.9l-1.2-0.9c-1.9-1.5-12.9-6.8-17.3-8.3c-5.6-2-12.1-3.6-17.9-4.4C135.8,37.1,122.2,37.1,116.3,38z"/>
                                        <path
                                            fill="#fff"
                                            d="M162.4,96.7l-26.8,30.9l14.7,0.1l14.6,0.1l-0.2,1.5c-0.2,0.8-0.8,3.5-1.4,5.9c-7.9,31.9-33.2,57.6-64.9,66c-5.8,1.5-13.8,2.8-17.5,2.8c-1.3,0-2.4,0.2-2.4,0.5c0,1.1,11.1,6.6,18.7,9.2c43.2,14.9,90.7-4.6,111.4-45.8c5.5-11,8.6-22.4,9.3-35l0.3-4.9l13.8-0.2l13.8-0.2l-26.7-29.1c-14.6-16-27.4-29.9-28.4-30.9l-1.8-1.8L162.4,96.7z"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div> :
                    <div className='is_account'>
                        {result?.email ? <p className={'is_account_email'}>{result?.email}</p> : null}
                        {!result?.is_counted ? <><h2 className='friends_page_title'>Farm is Over.</h2>
                            <p className={'is_account_descr'}>We are currently counting the number of tokens you have earned.</p>
                            <>
                                <p className={'is_account_descr'}>All information will be provided shortly. Stay in touch.</p>
                                <small className={'buy_description'}><i>When calculating the allocation amount, several criteria will be taken into account. Such as total earnings, final level, completion of main and additional tasks, presence of a multiplier, etc.</i></small>
                            </>
                        </> : result?.is_linked ? <>
                            <h2 className='friends_page_title' style={{margin: '0 0 12px'}}>Congratulation!!!</h2>
                            <p className={'is_account_descr'} style={{margin: '0'}}>You earned</p>
                            <p className={'is_account_descr'} style={{marginBottom: '10px'}}><span style={{
                                fontSize: '32px',
                                fontWeight: 'bold',
                                fontFamily: 'Saira-600, san-serif',
                                color: '#4D9636'
                            }}>{numberWithCommasNull(result?.airdrop_amount || 0)}</span> $MPEPPE</p>
                            <p className={'is_account_descr'} style={{margin: '0 0 12px'}}>Here are some your stats:</p>
                            <div className={'stat_item'}>
                                <span className={'stat_item_title'}>Final level:</span>
                                <span className={'stat_item_value'}>{result?.level}</span>
                            </div>
                            <div className={'stat_item'}>
                                <span className={'stat_item_title'}>Airdrop multiplier:</span>
                                <span className={'stat_item_value'}>{result?.is_multiplier ?
                                    <svg style={{width: '12px', height: '12px'}} width="25" height="25" viewBox="0 0 25 25" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12.5" cy="12.5" r="11.5" stroke="#17c65b" strokeWidth="2"/>
                                        <path d="M6.84619 12.0771L10.8462 16.577L18.3463 8.07698" stroke="#17c65b"
                                              strokeWidth="2"/>
                                    </svg> :
                                    <svg style={{width: '12px', height: '12px'}} width="800px" height="800px" viewBox="0 0 24 24" fill="nome"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z"
                                            fill="#D72727"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
                                              fill="#D72727"/>
                                    </svg>}</span>
                            </div>
                            <div className={'stat_item'}>
                                <span className={'stat_item_title'}>Total MP farm:</span>
                                <span
                                    className={'stat_item_value'}>{numberWithCommasNull(result?.total_balance || 0)}</span>
                            </div>
                            <div className={'stat_item'}>
                                <span className={'stat_item_title'}>Total farm by referrals:</span>
                                <span
                                    className={'stat_item_value'}>{numberWithCommasNull(result?.referral_balance || 0)}</span>
                            </div>
                            <div className={'stat_item'} style={{margin: '0 0 34px'}}>
                                <span className={'stat_item_title'}>Total games played:</span>
                                <span className={'stat_item_value'}>{result?.games_played}</span>
                            </div>
                            <>
                                <small className={'buy_description'}><i>Your tokens are credited to your balance in
                                    your <a style={{color: '#4D9636'}} target={'_blank'}
                                            href={'https://members.mpeppe.io/'}>mpeppe.io</a> account. Go to the
                                    'Transaction' section to view more information.</i></small>
                            </>
                        </> : <>
                            <h2 className='friends_page_title'>Unfortunately you are not eligible for airdrop!</h2>
                            <p className={'is_account_descr'}>If you think there is an error, please contact us via
                                email
                                <a style={{color: '#4D9636', paddingLeft: '4px'}} target={'_blank'}
                                   href={'mailto:support@mpeppe.io'}>support@mpeppe.io</a>.
                            </p>
                        </>}
                    </div>
                }
            </WithFrame>
        </WithLoading>
    );
};

export default withAirdrop(Airdrop);